<template>
  <v-list class="pt-0 pb-0">
    <template v-for="{ group, links: sublinks } of links">
      <v-list-group v-if="collapsibleSubGroups" :key="group + '-subgroup'">
        <template v-slot:activator>
          <v-list-item-title>{{ group }}</v-list-item-title>
        </template>
        <v-list-item v-for="{ title, to, href, icon } of sublinks" :key="group + '-' + title" :to="to" :href="href" :target="href ? '_blank' : null">
          <v-list-item-avatar v-if="icon">
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ title }}</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <template v-else>
        <v-chip :key="group + '-header'" label style="width:100%">{{ group }}</v-chip>
        <v-list-item v-for="{ title, to, href, icon } of sublinks" :key="group + '-' + title" :to="to" :href="href" :target="href ? '_blank' : null">
          <v-list-item-avatar v-if="icon">
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ title }}</v-list-item-title>
        </v-list-item>
      </template>
    </template>
    <template v-if="isEditor">
      <v-divider />
      <v-list-item to="/system/links">
        <v-list-item-avatar>
          <v-icon small>fal fa-pencil</v-icon>
        </v-list-item-avatar>
        <v-list-item-title>Edit Links</v-list-item-title>
      </v-list-item>
    </template>
  </v-list>
</template>
<script>
import { computed, ref, watch } from '@vue/composition-api'

export default {
  props: {
    category: {
      type: String,
      required: true
    },
    collapsibleSubGroups: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const links = ref([])

    const isEditor = ref(false)

    watch(() => props.category, async (category) => {
      const { data } = await root.$feathers.service('system/link-category').find({ query: { category } })
      if (data.length > 0) {
        for (const { name: group, links: groupLinks } of data[0].groups) {
          const obj = { group, links: [] }
          for (let i = 0; i < groupLinks.length; i++) {
            const link = await root.$feathers.service('system/link').get(groupLinks[i])
            obj.links.push(link)
          }
          links.value.push(obj)
        }
        if (data[0].editRoles.filter((role) => user.value.roles.includes(role)).length > 0) {
          isEditor.value = true
        }
      }
    })

    return {
      links,
      isEditor
    }
  }
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',{staticClass:"pt-0 pb-0"},[_vm._l((_vm.links),function(ref){
var group = ref.group;
var sublinks = ref.links;
return [(_vm.collapsibleSubGroups)?_c('v-list-group',{key:group + '-subgroup',scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v(_vm._s(group))])]},proxy:true}],null,true)},_vm._l((sublinks),function(ref){
var title = ref.title;
var to = ref.to;
var href = ref.href;
var icon = ref.icon;
return _c('v-list-item',{key:group + '-' + title,attrs:{"to":to,"href":href,"target":href ? '_blank' : null}},[(icon)?_c('v-list-item-avatar',[_c('v-icon',[_vm._v(_vm._s(icon))])],1):_vm._e(),_c('v-list-item-title',[_vm._v(_vm._s(title))])],1)}),1):[_c('v-chip',{key:group + '-header',staticStyle:{"width":"100%"},attrs:{"label":""}},[_vm._v(_vm._s(group))]),_vm._l((sublinks),function(ref){
var title = ref.title;
var to = ref.to;
var href = ref.href;
var icon = ref.icon;
return _c('v-list-item',{key:group + '-' + title,attrs:{"to":to,"href":href,"target":href ? '_blank' : null}},[(icon)?_c('v-list-item-avatar',[_c('v-icon',[_vm._v(_vm._s(icon))])],1):_vm._e(),_c('v-list-item-title',[_vm._v(_vm._s(title))])],1)})]]}),(_vm.isEditor)?[_c('v-divider'),_c('v-list-item',{attrs:{"to":"/system/links"}},[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fal fa-pencil")])],1),_c('v-list-item-title',[_vm._v("Edit Links")])],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }